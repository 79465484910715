import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ProjectSearchDto } from "./dto/ProjectSearchDto";
import { Document } from "./entity/Document";
import { Measurement } from "./entity/Measurement";
import { Options } from "./entity/Options";
import { Project } from "./entity/Project";
import { Settings } from "./entity/Settings";
import { SettingsColor } from "./entity/SettingsColor";
import { SettingsExportOption } from "./entity/SettingsExportOption";
import { SettingsSashConfiguration } from "./entity/SettingsSashConfiguration";
import { SettingsWeatherboard } from "./entity/SettingsWeatherboard";
import { UserAcount } from "./entity/UserAccount";
import { WindowSystem } from "./entity/WindowSystem";
import { WindowSystemFamily } from "./entity/WindowSystemFamily";
// import axios, { Options as AxiosRequestConfig } from "redaxios";
import { store } from "../../store/index";
import { MeasurementPriceDto } from "./dto/MeasurementPriceDto";
import { ParentEntityType } from "./entity/enum/ParentEntityType";
import { FrameSystem } from "./entity/FrameSystem";
import { SettingsConstructionType } from "./entity/SettingsConstructionType";
import { SettingsSystemTypeDefaultValues } from "./entity/SettingsSystemTypeDefaultValues";
import { SettingsWindowSystemFamilyDefaultValues } from "./entity/SettingsWindowSystemFamilyDefaultValues";
import { Oidc } from "./Oidc";

export class RestClient {
  //axios.defaults.withCredentials = true;

  // private static const baseUrl = "http://localhost:8080/fenestraCalc/rest/v1";
  // worker has no access to window
  private static baseUrl = process.env.VUE_APP_FENESTRACALC_URL
    ? process.env.VUE_APP_FENESTRACALC_URL + "/rest/v1"
    : "https://" +
      self.location.host +
      "/" +
      self.location.pathname.split("/")[1] +
      "/rest/v1";

  public static getProjectOfferUrl(project: Project): string {
    return this.baseUrl + "/download/offer/" + project.id;
  }

  public static async getProjectEntryYears(): Promise<number[]> {
    return await this.getData("/projects/years");
  }

  public static async getProjectById(projectId: number): Promise<Project> {
    return await this.getData("/project/" + projectId);
  }

  public static async getProjectPrices(
    project: Project
  ): Promise<MeasurementPriceDto[]> {
    return await this.getData("/project/" + project.id + "/prices");
  }

  public static async getMeasurementPrice(
    measurement: Measurement
  ): Promise<number | null> {
    return await this.getData("/measurement/" + measurement.id + "/price");
  }

  public static async findProjects(
    year: number | null,
    filterString: string | null,
    projectLeader: UserAcount | null
  ): Promise<ProjectSearchDto[]> {
    let filter = "";
    if (year) {
      filter += "&year=" + year;
    }
    if (filterString) {
      filter += "&filterString=" + encodeURI(filterString);
    }
    if (projectLeader) {
      filter += "&projectLeaderId=" + projectLeader.id;
    }
    return await this.getData("/projects?" + filter);
  }

  public static async getUserProjects(): Promise<Project[]> {
    return await this.getData("/projects/user");
  }

  public static async addProjectToUser(project: Project) {
    await this.getProjectById(project.id as number);
  }

  public static async addProjectToUserById(projectId: number) {
    await this.postData(`/project/${projectId}/addUser`, null);
  }

  public static async removeProjectFromUser(project: Project) {
    await this.postData(`/project/${project.id}/deleteUser`, null);
  }

  public static async saveProject(project: Project): Promise<Project> {
    return await this.postData("/project", project);
  }

  public static async getMeasurementsByProject(
    project: Project
  ): Promise<Measurement[]> {
    return await this.getData("/project/" + project.id + "/measurements");
  }

  public static async saveMeasurement(
    measurement: Measurement
  ): Promise<Measurement> {
    return await this.postData("/measurement", measurement);
  }

  public static async deleteMeasurement(measurementId: number) {
    await this.deleteData("/measurement", measurementId);
  }

  public static async getOptions(): Promise<Options> {
    return await this.getData("/options");
  }

  public static async getSettings(): Promise<Settings[]> {
    return await this.getData("/settings");
  }

  public static async getSettingsColors(): Promise<SettingsColor[]> {
    return await this.getData("/settings/colors");
  }

  public static async getSettingsConstructionType(): Promise<
    SettingsConstructionType[]
  > {
    return await this.getData("/settings/constructionTypes");
  }

  public static async getSettingsWeatherboards(): Promise<
    SettingsWeatherboard[]
  > {
    return await this.getData("/settings/weatherboards");
  }

  public static async getSettingsWindowSystemFamilies(): Promise<
    WindowSystemFamily[]
  > {
    return await this.getData("/settings/windowSystemFamilies");
  }

  public static async getSettingsFrameSystems(): Promise<FrameSystem[]> {
    return await this.getData("/settings/frameSystems");
  }

  public static async getSettingsSashConfigurations(): Promise<
    SettingsSashConfiguration[]
  > {
    return await this.getData("/settings/sashConfigurations");
  }

  public static async getSettingsExportOptions(): Promise<
    SettingsExportOption[]
  > {
    return await this.getData("/settings/exportOptions");
  }

  /*
   * Returns a Document, WITHOUT it's binary part!
   * TODO: isn't used at the moment. Remove it. Id doesn't seem to do anything with sense
   */
  public static async getDocument(documentId: number): Promise<Document> {
    const document: Document = await this.getData("/document/" + documentId);
    const binaryData = await this.getBlob("/document/binary/" + documentId);
    document.document = null;
    document.isDirty = false;
    return document;
  }

  public static async getDocumentBinary(documentId: number): Promise<Blob> {
    const binaryData = await this.getBlob("/document/binary/" + documentId);
    return binaryData;
  }

  /*
   * Returns all the Documents of a parentEntity. But WITHOUT the binary data!
   */
  public static async getDocuments(
    parentId: number,
    parentType: ParentEntityType
  ): Promise<Document[]> {
    let documents = [];
    switch (parentType) {
      case ParentEntityType.Measurement:
        documents = await this.getData("/document/measurement/" + parentId);
        break;
      case ParentEntityType.Project:
        documents = await this.getData("/document/project/" + parentId);
        break;
    }
    return documents;
  }

  public static async saveDocument(document: Document): Promise<number> {
    const form = new FormData();
    const file = document.document!;
    const tmpDocument: Document = {
      id: document.id,
      contentType: document.contentType,
      filename: document.filename,
      isRestDelete: document.isRestDelete,
      restDelete: document.restDelete,
      isDirty: document.isDirty,
      version: document.version,
      date: document.date,
      description: document.description,
      parentEntityId: document.parentEntityId,
      parentEntityType: document.parentEntityType,
    } as Document;
    form.append("document", JSON.stringify(tmpDocument));
    form.append("file", file);
    return await this.postFormData("/document", form);
  }

  public static async deleteDocument(document: Document) {
    await this.deleteData("/document", document.id);
  }

  public static async getWindowSystems(): Promise<WindowSystem[]> {
    return await this.getData("/windowSystems");
  }

  public static async getSettingsWindowSystemFamilyDefaultValues(): Promise<
    SettingsWindowSystemFamilyDefaultValues[]
  > {
    return await this.getData("/settings/windowSystemFamilyDefaultValues");
  }

  public static async getSettingsSystemTypeDefaultValues(): Promise<
    SettingsSystemTypeDefaultValues[]
  > {
    return await this.getData("/settings/systemTypeDefaultValues");
  }

  public static async getUser(): Promise<UserAcount> {
    return await this.getData("/user");
  }

  public static async isOnline(): Promise<boolean> {
    //TODO: test
    try {
      await this.getData("/ping");
      return true;
    } catch (error) {
      return false;
    }
  }
  public static async isLoggedIn(): Promise<boolean> {
    const options: AxiosRequestConfig = {
      method: "GET",
      url: this.baseUrl + "/user",
      withCredentials: true,
    };
    try {
      const response = await axios(options);
      // no problems ? we are authenticated (maybe check for http 200 reply)
      return true;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError;
        if (error.response!.status === 403 || error.response!.status === 401) {
          return false;
        }
        // we assume we are offline, or there is a server problem. Then try to use the offline data
        return true;
      }
    }
    return true;
  }

  private static async postFormData(subUrl: string, object: unknown) {
    // this could also be a normal postFormatData method, if we wouldn't update
    // the progress bar.
    const options: AxiosRequestConfig = {
      method: "POST",
      url: this.baseUrl + subUrl,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      onUploadProgress: (event) => {
        const progressEvent = <ProgressEvent>event;
        // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // todo: if we get to often, that the progress is more, than the totalKb to transfer, then  send the total kb here
        const progress = {
          kbTransfered: Math.round(progressEvent.loaded / 1024),
          kbTotal: Math.round(progressEvent.total / 1024),
        };
        store.commit("setUploadProgress", progress);
      },
      data: object,
    };
    const response = await axios(options);
    if (response) {
      // We don't always get a response back. and as it seems, sometimes
      // it is already an object not a string anymore! ??
      // if (typeof response.data === "string") {
      //   return JSON.parse(response.data);
      // } else {
      return response.data;
      // }
    }
  }

  private static async postData(subUrl: string, object: unknown) {
    const options: AxiosRequestConfig = {
      method: "POST",
      url: this.baseUrl + subUrl,
      data: object,
      withCredentials: true,
    };
    const response = await axios(options);
    if (response) {
      // We don't always get a response back. and as it seems, sometimes
      // it is already an object not a string anymore! ??
      // if (typeof response.data === "string") {
      //   console.log(response);
      //   return JSON.parse(response.data);
      // } else {
      return response.data;
      // }
    }
  }
  private static async getData(subUrl: string) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: this.baseUrl + subUrl,
      withCredentials: true,
    };

    // axios doesn't support fetch api yet. only XmlHTTPRequest, which is NOT supported in
    // service worker :-(
    // if (window.XMLHttpRequest) {
    const response = await axios(options);
    // } else {
    //   const response = await fetch(this.baseUrl + subUrl, {
    //     credentials: "include"
    //   });

    // }
    // const response = await request.get(options);
    //let result = JSON.parse(response.data);
    return response.data;
  }

  private static async getBlob(subUrl: string) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: this.baseUrl + subUrl,
      withCredentials: true,

      responseType: "blob",
      onDownloadProgress: (event) => {
        const progressEvent = <ProgressEvent>event;
        const progress = {
          kbTransfered: Math.round(progressEvent.loaded / 1024),
          kbTotal: Math.round(progressEvent.total / 1024),
        };
        store.commit("setDownloadProgress", progress);
      },
    };
    const response = await axios(options);
    // const response = await request.get(options);
    //let result = JSON.parse(response.data);
    return response.data;
  }

  private static async deleteData(subUrl: string, object: unknown) {
    // TODO: test!
    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: this.baseUrl + subUrl,
      data: object,
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };
    await axios(options);
  }
}

export function setupAuthenticationHeaders(
  axiosInstance: AxiosInstance
): AxiosInstance {
  const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = Oidc.getToken();
    if (token) {
      // TODO: should we redirekt or something if there is no token?
      // propably not, we'll do that in the router
      config.headers!["Authorization"] = `Bearer ${token}`;
    }
    // console.info(`[request] [${JSON.stringify(config)}]`);
    return config;
  };

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  };

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
  };

  const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
    //console.error(`[response error] [${JSON.stringify(error)}]`);
    // todo: propably if we get a 403, permission denied. we need to get a new token =>
    // redirect user. but we can-t do that here. We need to be sure, that if the user tried to save changes, that
    // they are stored locally!
    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      error.config.method?.toLowerCase() === "get"
    ) {
      // if we didn-t have the permission for a get request. we redirect directly. for a post or delete request we can't do that, since it need to be stored locally first
      // todo: would be better to store it locally first, then store it to server, and on success, delete the local result, otherwise leave it (would also integrate better with automatic db sync)
      // TODO: it-ss a bit error proun, since the other request continue running... so we can get a LOOOT of redirects.
      // TODO: Catch that somewhere!!
      //await Oidc.authenticate();
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
