import { Measurement } from "../entity/Measurement";
import { Project } from "../entity/Project";
import { Data } from "../Data";
import { MeasurementTrade } from "../entity/MeasurementTrade";
import { MeasurementCloneDescriptor } from "./MeasurementCloneDescriptor";

export class MeasurementService {
  public static async cloneMeasurement(
    measurement: Measurement,
    project: Project,
    cloneDescriptor: MeasurementCloneDescriptor
  ): Promise<Measurement> {
    const newMeasurement = await this.getNewProjectMeasurement(project);
    newMeasurement.positionNr = await this.getNewMeasurementPositionNr(project);

    if (cloneDescriptor.clonePosition) {
      newMeasurement.room = measurement.room;
      //newMeasurement.noOfWindows = measurement.noOfWindows;
      newMeasurement.estimatedHours = measurement.estimatedHours;
      newMeasurement.floor = measurement.floor;
      newMeasurement.settingsConstructionType =
        measurement.settingsConstructionType;
    }

    if (cloneDescriptor.cloneConfiguration) {
      newMeasurement.settingsWindowSystemFamily =
        measurement.settingsWindowSystemFamily;
      newMeasurement.settingsAlternativeWindowSystemFamily =
        measurement.settingsAlternativeWindowSystemFamily;
      newMeasurement.settingsOptionDesignStyle =
        measurement.settingsOptionDesignStyle;
      newMeasurement.settingsInnerColor = measurement.settingsInnerColor;
      newMeasurement.hasManualInnerColor = measurement.hasManualInnerColor;
      newMeasurement.manualInnerColor = measurement.manualInnerColor;
      newMeasurement.settingsOptionHardwareType =
        measurement.settingsOptionHardwareType;
      newMeasurement.settingsOptionInnerHandle =
        measurement.settingsOptionInnerHandle;
      newMeasurement.settingsOptionOuterHandle =
        measurement.settingsOptionOuterHandle;
      newMeasurement.settingsOptionResistanceClass =
        measurement.settingsOptionResistanceClass;
      newMeasurement.settingsOptionGlassStructure =
        measurement.settingsOptionGlassStructure;
      newMeasurement.settingsWeatherboard = measurement.settingsWeatherboard;
      newMeasurement.settingsOuterColor = measurement.settingsOuterColor;
      newMeasurement.hasManualOuterColor = measurement.hasManualOuterColor;
      newMeasurement.manualOuterColor = measurement.manualOuterColor;
      newMeasurement.settingsOptionGlass = measurement.settingsOptionGlass;
      newMeasurement.settingsOptionFlyScreen =
        measurement.settingsOptionFlyScreen;
      newMeasurement.hasBlinds = measurement.hasBlinds;
      newMeasurement.settingsOptionGlazingBarModel =
        measurement.settingsOptionGlazingBarModel;
      newMeasurement.settingsOptionGlazingBarWidth =
        measurement.settingsOptionGlazingBarWidth;
      newMeasurement.hasLockCylinder = measurement.hasLockCylinder;
      newMeasurement.settingsExportOptions = measurement.settingsExportOptions;
    }

    if (cloneDescriptor.cloneSashes) {
      newMeasurement.settingsOptionNoOfSashes =
        measurement.settingsOptionNoOfSashes;
      newMeasurement.settingsSashConfiguration =
        measurement.settingsSashConfiguration;
      newMeasurement.settingsOptionSashOpeningDirection1 =
        measurement.settingsOptionSashOpeningDirection1;
      newMeasurement.settingsOptionSashOpeningDirection2 =
        measurement.settingsOptionSashOpeningDirection2;
      newMeasurement.settingsOptionSashOpeningDirection3 =
        measurement.settingsOptionSashOpeningDirection3;
      newMeasurement.settingsOptionSashOpeningDirection4 =
        measurement.settingsOptionSashOpeningDirection4;
      newMeasurement.settingsOptionSashGearbox1 =
        measurement.settingsOptionSashGearbox1;
      newMeasurement.settingsOptionSashGearbox2 =
        measurement.settingsOptionSashGearbox2;
      newMeasurement.settingsOptionSashGearbox3 =
        measurement.settingsOptionSashGearbox3;
      newMeasurement.settingsOptionSashGearbox4 =
        measurement.settingsOptionSashGearbox4;
      newMeasurement.settingsOptionSashType1 =
        measurement.settingsOptionSashType1;
      newMeasurement.settingsOptionSashType2 =
        measurement.settingsOptionSashType2;
      newMeasurement.settingsOptionSashType3 =
        measurement.settingsOptionSashType3;
      newMeasurement.settingsOptionSashType4 =
        measurement.settingsOptionSashType4;
    }

    if (cloneDescriptor.cloneNotes) {
      newMeasurement.comment = measurement.comment;
    }

    if (cloneDescriptor.cloneInnerMeasurements) {
      newMeasurement.innerHeight = measurement.innerHeight;
      newMeasurement.innerWidth = measurement.innerWidth;
    }
    if (cloneDescriptor.cloneOuterMeasurements) {
      newMeasurement.outerHeight = measurement.outerHeight;
      newMeasurement.outerWidth = measurement.outerWidth;
    }

    if (cloneDescriptor.cloneDetails) {
      newMeasurement.settingsOptionTopDetail =
        measurement.settingsOptionTopDetail;
      newMeasurement.settingsOptionBottomDetail =
        measurement.settingsOptionBottomDetail;
      newMeasurement.settingsOptionLeftDetail =
        measurement.settingsOptionLeftDetail;
      newMeasurement.settingsOptionRightDetail =
        measurement.settingsOptionRightDetail;
    }
    if (cloneDescriptor.cloneOtherFineMeasurements) {
      newMeasurement.sashHeight1 = measurement.sashHeight1;
      newMeasurement.sashHeight2 = measurement.sashHeight2;
      newMeasurement.sashHeight3 = measurement.sashHeight3;
      newMeasurement.sashHeight4 = measurement.sashHeight4;

      newMeasurement.sashWidth1 = measurement.sashWidth1;
      newMeasurement.sashWidth2 = measurement.sashWidth2;
      newMeasurement.sashWidth3 = measurement.sashWidth3;
      newMeasurement.sashWidth4 = measurement.sashWidth4;

      newMeasurement.isManualSashWidth1 = measurement.isManualSashWidth1;
      newMeasurement.isManualSashWidth2 = measurement.isManualSashWidth2;
      newMeasurement.isManualSashWidth3 = measurement.isManualSashWidth3;
      newMeasurement.isManualSashWidth4 = measurement.isManualSashWidth4;

      newMeasurement.isManualSashHeight1 = measurement.isManualSashHeight1;
      newMeasurement.isManualSashHeight2 = measurement.isManualSashHeight2;
      newMeasurement.isManualSashHeight3 = measurement.isManualSashHeight3;
      newMeasurement.isManualSashHeight4 = measurement.isManualSashHeight4;

      newMeasurement.leftOverhang = measurement.leftOverhang;
      newMeasurement.rightOverhang = measurement.rightOverhang;
      newMeasurement.topOverhang = measurement.topOverhang;
      newMeasurement.bottomOverhang = measurement.bottomOverhang;

      newMeasurement.leftStop = measurement.leftStop;
      newMeasurement.rightStop = measurement.rightStop;
      newMeasurement.topStop = measurement.topStop;
      newMeasurement.bottomStop = measurement.bottomStop;

      newMeasurement.leftDetailSize = measurement.leftDetailSize;
      newMeasurement.rightDetailSize = measurement.rightDetailSize;
      newMeasurement.topDetailSize = measurement.topDetailSize;

      newMeasurement.orderTopFrameExtension =
        measurement.orderTopFrameExtension;
      newMeasurement.orderBottomFrameExtension =
        measurement.orderBottomFrameExtension;
      newMeasurement.orderLeftFrameExtension =
        measurement.orderLeftFrameExtension;
      newMeasurement.orderRightFrameExtension =
        measurement.orderRightFrameExtension;
      newMeasurement.settingsTopFrameSystem =
        measurement.settingsTopFrameSystem;
      newMeasurement.settingsBottomFrameSystem =
        measurement.settingsBottomFrameSystem;
      newMeasurement.settingsLeftFrameSystem =
        measurement.settingsLeftFrameSystem;
      newMeasurement.settingsRightFrameSystem =
        measurement.settingsRightFrameSystem;

      newMeasurement.hasShortenedAluminiumShell =
        measurement.hasShortenedAluminiumShell;

      newMeasurement.subtractLeftFrameExtensionFromOrder =
        measurement.subtractLeftFrameExtensionFromOrder;
      newMeasurement.subtractRightFrameExtensionFromOrder =
        measurement.subtractRightFrameExtensionFromOrder;
      newMeasurement.subtractTopFrameExtensionFromOrder =
        measurement.subtractTopFrameExtensionFromOrder;
      newMeasurement.subtractBottomFrameExtensionFromOrder =
        measurement.subtractBottomFrameExtensionFromOrder;

      newMeasurement.isOrderCalculatedAutomatically =
        measurement.isOrderCalculatedAutomatically;
      newMeasurement.orderHeight = measurement.orderHeight;
      newMeasurement.orderWidth = measurement.orderWidth;
      newMeasurement.isBottomFrameExtensionFloorConnectionProfile =
        measurement.isBottomFrameExtensionFloorConnectionProfile;
      newMeasurement.isTopFrameExtensionSwissFrame =
        measurement.isTopFrameExtensionSwissFrame;

      newMeasurement.hasExternalInsulation = measurement.hasExternalInsulation;
      newMeasurement.leftExternalInsulation =
        measurement.leftExternalInsulation;
      newMeasurement.bottomExternalInsulation =
        measurement.bottomExternalInsulation;
      newMeasurement.rightExternalInsulation =
        measurement.rightExternalInsulation;
      newMeasurement.topExternalInsulation = measurement.topExternalInsulation;
    }

    return newMeasurement;
  }

  public static async getNewProjectMeasurement(
    project: Project
  ): Promise<Measurement> {
    // create a new measurement and set project id it belongs to.
    const settings = await Data.getSettings();
    const options = await Data.getOptions();
    const measurement: Measurement = {
      id: null,
      projectId: project.id!,
      positionNr: await this.getNewMeasurementPositionNr(project),
      noOfWindows: 1,
      leftDetailSize: settings.DEFAULT_LEFT_INTO_LIGHT,
      rightDetailSize: settings.DEFAULT_RIGHT_INTO_LIGHT,
      topDetailSize: settings.DEFAULT_TOP_INTO_LIGHT,
      //settingsOptionProjectType: Data.findDefaultValue(options.PROJECT_TYPE),
      settingsConstructionType: null,
      settingsWindowSystemFamily: null,
      settingsAlternativeWindowSystemFamily: null,
      settingsOptionDesignStyle: null,
      settingsOptionGlass: null,
      settingsOptionGlassStructure: null,
      settingsOptionHardwareType: null,
      settingsOptionInnerHandle: null,
      settingsOptionOuterHandle: null,
      settingsOptionResistanceClass: null,
      settingsOptionFlyScreen: null,
      settingsOptionPleatedBlinds: null,
      settingsOptionNoOfSashes: null,
      settingsOptionBottomDetail: null,
      settingsOptionTopDetail: null,
      settingsOptionLeftDetail: null,
      settingsOptionRightDetail: null,
      settingsOptionGlazingBarModel: null,
      settingsOptionGlazingBarWidth: null,
      settingsInnerColor: null,
      settingsOuterColor: null,
      settingsSashConfiguration: null,
      settingsTopFrameSystem: null,
      settingsLeftFrameSystem: null,
      settingsRightFrameSystem: null,
      settingsBottomFrameSystem: null,
      settingsWeatherboard: null,
      projectMeasurementTrades: [] as MeasurementTrade[],
      isOrderCalculatedAutomatically: true,
      subtractRightFrameExtensionFromOrder: false,
      subtractLeftFrameExtensionFromOrder: false,
      subtractTopFrameExtensionFromOrder: false,
      subtractBottomFrameExtensionFromOrder: false,
      hasOrderApproval: false,
      hasBlinds: false,
      hasShortenedAluminiumShell: false,
      hasManualInnerColor: false,
      hasManualOuterColor: false,
      hasLockCylinder: false,
      isBottomFrameExtensionFloorConnectionProfile: false,
      settingsExportOptions: [] as number[],
      isTopFrameExtensionSwissFrame: false,
      bottomInstallationLeeway: null,
      isManualSashHeight1: false,
      isManualSashHeight2: false,
      isManualSashHeight3: false,
      isManualSashHeight4: false,
      isManualSashWidth1: false,
      isManualSashWidth2: false,
      isManualSashWidth3: false,
      isManualSashWidth4: false,
      hasExternalInsulation: false,
      settingsOptionSashGearbox1: null,
      settingsOptionSashGearbox2: null,
      settingsOptionSashGearbox3: null,
      settingsOptionSashGearbox4: null,
      settingsOptionSashOpeningDirection1: null,
      settingsOptionSashOpeningDirection2: null,
      settingsOptionSashOpeningDirection3: null,
      settingsOptionSashOpeningDirection4: null,
      settingsOptionSashType1: null,
      settingsOptionSashType2: null,
      settingsOptionSashType3: null,
      settingsOptionSashType4: null,
      rightInstallationLeeway: null,
      leftInstallationLeeway: null,
      topInstallationLeeway: null,
      orderHeight: null,
      orderWidth: null,
      orderLeftFrameExtension: null,
      orderRightFrameExtension: null,
      orderTopFrameExtension: null,
      orderBottomFrameExtension: null,
      comment: null,
      innerHeight: null,
      innerWidth: null,
      outerHeight: null,
      outerWidth: null,
      sashWidth1: null,
      sashWidth2: null,
      sashWidth3: null,
      sashWidth4: null,
      sashHeight1: null,
      sashHeight2: null,
      sashHeight3: null,
      sashHeight4: null,
      manualInnerColor: null,
      manualOuterColor: null,
      floor: null,
      room: null,
      bottomStop: null,
      topStop: null,
      leftStop: null,
      rightStop: null,
      bottomOverhang: null,
      topOverhang: null,
      leftOverhang: null,
      rightOverhang: null,
      estimatedHours: null,
      isRestDelete: false,
      topExternalInsulation: null,
      leftExternalInsulation: null,
      bottomExternalInsulation: null,
      rightExternalInsulation: null,
      version: 0,
    };
    return measurement;
  }

  public static async getNewMeasurementPositionNr(
    project: Project
  ): Promise<number> {
    const measurements = await Data.getMeasurements(project.id!);
    let highestPosNr = 0;
    for (const measurement of measurements) {
      if (measurement.positionNr && measurement.positionNr > highestPosNr) {
        highestPosNr = measurement.positionNr;
      }
    }
    // get next highest multiple of 10
    return highestPosNr - (highestPosNr % 10) + 10;
  }
}
