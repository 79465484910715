import LogRocket from "logrocket";

export class Logger {
  public static debug(msg: string) {
    // we don't send debug messages to logrocket, when we are debbuging they appear there anyway
    // LogRocket.captureMessage(msg);
    console.debug(msg);
  }

  public static info(msg: string) {
    // LogRocket.info(msg);
    console.info(msg);
  }

  public static warn(msg: string) {
    // LogRocket.warn(msg);
    console.warn(msg);
  }

  public static error(msg: string) {
    // LogRocket.error(msg);
    console.error(msg);
  }

  public static logException(error: Error, method?: string) {
    LogRocket.captureException(error, {
      tags: { catchedException: true },
      extra: { method: method ? method : "unknown" },
    });
    console.error(error.message);
    if (error.stack) {
      console.error(error.stack);
    }
  }
}
