import { Db } from "./Db";
import { RestClient } from "./RestClient";
import { Data } from "./Data";
import { Measurement } from "./entity/Measurement";
import { DirtyEntity } from "./entity/DirtyEntity";
import { Document } from "./entity/Document";
import { ParentEntityType } from "./entity/enum/ParentEntityType";
import { SyncEvents } from "@/SyncEvents";
import { Logger } from "./Logger";
import { store } from "../../store/index";
import { Project } from "./entity/Project";
import { DirtyProject } from "./entity/DirtyProject";
import { ChangedEntity } from "./ChangedEntity";
import { EntityType } from "./entity/enum/EntityType";

// TODO: move all methods from Data.ts here. Which have to do with syncing
// like getProject, and save*
export class SyncData {
  private static db = Db.Instance;
  private static isFetchRunning = false;
  private static isPostRunning = false;

  private constructor() {
    // so nobody can initialize it!
  }

  /*
   * starts sync of all the dirty data.
   * awaits until projects and measurements are synced. but NOT until the documents are synced
   */
  public static async syncDirtyData() {
    await this.syncDirtyProjects();
    await this.syncDirtyMeasurements();
    this.syncDirtyDocuments();
  }

  // returns the document if it was stored or already existed, otherwise null
  public static async saveDocument(
    document: Document
  ): Promise<Document | null> {
    // TODO: use transactions
    let returnDocument: Document | null = null;
    let storeOnServer = document.isDirty;

    if (!document.id && !document.isRestDelete) {
      // new document
      document.id = await this.getNewDocumentId();
      storeOnServer = true;
    } else if (document.isRestDelete) {
      // delete the document
      if (!document.id) {
        // this one has never been stored, not even locally.
        // do nothing!
        // I think that never happens anymore, since we always store documents
        // directly. But we leave it if anything chances in the future
        returnDocument = null;
        storeOnServer = false;
      } else if (isNaN(Number(document.id))) {
        // thats a document that has never been stored on the server.
        // just delete it locally!
        // TODO: Make sure we are not uploading the document at the same time!!!!
        // TODO: what happens if it is in the upload queue?
        // TODO: post directly to the sync thread. Cancel the upload, if it is the same document
        await this.db.documents.delete(document.id);
        await this.db.dirtyDocuments.delete(document.id);
        Logger.debug(
          "Deleted file locally and on Server. Document Id: " + document.id
        );
        returnDocument = null;
        storeOnServer = false;
      } else {
        storeOnServer = true;
      }
      // if the document was already stored on the server. We sync it like a new or changed document.
    }

    if (storeOnServer) {
      // TODO: use transaction
      // TODO: if we have a document which we already stored, but didn't upload to the server yet. Then we paint in it, so it gets dirty. but meanwhile the document got stored
      // on the server and got a new ID. If the user stores the document now, if will be stored with the old temporal id again!
      await this.db.dirtyDocuments.put({
        id: document.id!,
      });
      // we always need to store it, even if it wasn't dirty. but maybe the measurement id changed!
      await this.db.documents.put(document);
      store.commit("addUpload", document.id);
      returnDocument = document;
      // Start background sync process
      this.initDocumentPostSync();
    }
    return returnDocument;
  }

  /*
   * DOwnloads all documents which aren't in local db yet, or which changed on server.
   */
  public static async fetchDocuments() {
    // TODO: this only works inside of the same tab
    // if it is opened in another tab, that won't work :-(
    // it will be run a 2nd time anyway.
    // once that here always runs in the service worker. we won't have that problem anymore
    // we could also use an attribue, marking a file asbeeing synced in the db. in combination with transactions

    // TODO: it could happen, that right now we are download a document, which we add to the list again.
    // it will be downloaded twice then :-( we need to store in the store which is the current downloading element
    for (const project of await this.db.projects.toArray()) {
      if (!isNaN(Number(project.id))) {
        try {
          //returns the document list for all the projects
          const documents = await RestClient.getDocuments(
            project.id as number,
            ParentEntityType.Project
          );
          // the following function only downloads documents which weren't downloaded yet
          await this.addMissingDocumentsToDownloadQueue(documents);
          // Delete Docs from db which don't exist on the server anymore
          await this.removeDeletedDocumentsFromDb(
            documents,
            project.id as number,
            ParentEntityType.Project
          );
        } catch (error) {
          Logger.warn(
            "Tried to fetch a document or a project that doesn't exist:" + error
          );
        }
      }
    }
    for (const measurement of await this.db.measurements.toArray()) {
      if (!isNaN(Number(measurement.id))) {
        try {
          const documents = await RestClient.getDocuments(
            measurement.id as number,
            ParentEntityType.Measurement
          );
          await this.addMissingDocumentsToDownloadQueue(documents);
          await this.removeDeletedDocumentsFromDb(
            documents,
            measurement.id as number,
            ParentEntityType.Measurement
          );
        } catch (error) {
          Logger.warn(
            "Tried to fetch a document or a measuerement that doesn't exist:" +
              error
          );
        }
      }
    }
  }

  private static async syncMissingDocuments() {
    Logger.debug("is already fetching documents: " + this.isFetchRunning);
    if (!this.isFetchRunning) {
      try {
        this.isFetchRunning = true;
        Logger.debug("start fetching documents");
        for (
          let missingDocumentId: number;
          (missingDocumentId = await store.dispatch("getNextDownload"));

        ) {
          const blob = await this.fetchDocumentBinary(missingDocumentId);
          Logger.debug(
            "Storing newly downloaded document: " + missingDocumentId
          );
          // The document already exists in DB. otherwise it would never have been queued.
          // get it, and attach the binary! :-)
          const dbDocument = (await this.db.documents.get(missingDocumentId))!;
          dbDocument.document = blob;
          await this.db.documents.put(dbDocument);
          store.commit("addDownloadedDocumentBinaryToQueue", dbDocument.id);
        }
      } catch (e: any) {
        if (e.isAxiosError) {
          Logger.error("Lost connection while fetching documents, aborting.");
        } else {
          Logger.error(
            "Error while fetching documents, aborting. Showing error that was catched:"
          );
          Logger.logException(e, "SyncData.syncMissingDocuments");
        }
      } finally {
        this.isFetchRunning = false;
        Logger.debug("finished fetching documents");
      }
    }
  }

  private static async fetchDocumentBinary(documentId: number): Promise<Blob> {
    return await RestClient.getDocumentBinary(documentId);
  }

  /*
   * Adds the docuents from the list, which weren't downloaded yet, to the download queue.
   * Therefore we firs add the document WITHOUT the binary to the db, so we can show to the user, that his document is still beeing downloaded,
   * and add the download of the binary to the queue.
   */
  private static async addMissingDocumentsToDownloadQueue(
    documents: Document[]
  ) {
    for (const document of documents) {
      let dbDocument = await this.db.documents.get(document.id!);
      if (!dbDocument) {
        // The document is unknown. Store it to db, without the binary
        this.db.documents.put(document);
        dbDocument = document;
      }

      if (!dbDocument.document || dbDocument.document.size == 0) {
        // We haven't got the binary yet! queue it!
        // or as it seems it could be stored with size of 0. then also gt it again!        

        // TODO: we should also verify if the document already exist in local Database. if it maybe changed on server?
        // TODO: check if version the same, to be sure document didn't change on server
        // (actually when saving a document to the server, we don't get the new version back, so that will not work at the moment.)
        store.commit("addDownload", document.id);
        if (!this.isFetchRunning) {
          this.syncMissingDocuments();
        }
      }
    }
  }

  private static async removeDeletedDocumentsFromDb(
    documents: Document[],
    parentEntityId: number,
    parentEntityType: ParentEntityType
  ) {
    const dbDocuments = await this.db.documents
      .where(["parentEntityId", "parentEntityType"])
      .equals([parentEntityId, parentEntityType])
      .toArray();
    // All the dbDocuments which are not in document (the just downloaded list of documents), where removed on the server
    // so remove them also from the local db. (But only if they don't have an alphanumeric character in the id. because then they weren't uploaded yet!)
    for (const dbDocument of dbDocuments) {
      if (
        !isNaN(Number(dbDocument.id)) &&
        !documents.find((document) => document.id == dbDocument.id)
      ) {
        Logger.debug(
          "Following document doesn't exist on the server. Only deleting it in local DB: " +
            dbDocument.id +
            "; parentEntity: " +
            parentEntityId +
            "; parentEntityType: " +
            parentEntityType
        );
        await this.db.documents.delete(dbDocument.id!);
      }
    }
    // TODO: add one point we should also empty the database from documents from projects/Measurements which don't exist anymore
    // or which the user doesn't acess anymore
  }

  private static async syncDirtyProjects() {
    const dirtyProjects = await this.db.dirtyProjects.toArray();
    for (const dirtyProject of dirtyProjects) {
      this.syncDirtyProject(dirtyProject);
    }
  }

  public static async syncDirtyProjectById(
    dirtyProjectId: number | string
  ): Promise<Project | null> {
    const dirtyProject = await this.db.dirtyProjects.get(dirtyProjectId);
    return await this.syncDirtyProject(dirtyProject!);
  }

  private static async syncDirtyProject(
    dirtyProject: DirtyProject
  ): Promise<Project | null> {
    let project = (await this.db.projects.get(dirtyProject.id)) || null;
    if (project) {
      let oldProjectId;
      if (isNaN(Number(project.id))) {
        // new measurement
        oldProjectId = project.id;
        project.id = null;
        project.version = 0;
        // replace the projectId for all projectTrades with null.
        // the server will fill in the necessary changes... argh.. we should redo the whole sync concept
        for (const trade of project.projectTrades) {
          trade.project = null;
        }
      }

      project = await RestClient.saveProject(project);
      await this.db.projects.put(project);

      if (oldProjectId) {
        const changedEntity: ChangedEntity = {
          newId: project.id as number,
          oldId: oldProjectId as string,
          entityType: EntityType.Project,
        };
        store.commit("addChangedEntityToQueue", changedEntity);

        // this was a new project!
        // delete the old one
        await this.db.projects.delete(oldProjectId);
        // actualize the dirty measurements
        await this.db.measurements
          .where({
            projectId: oldProjectId,
          })
          .modify({
            projectId: project.id,
          });
        // actualize the documents
        await this.db.documents
          .where({
            parentEntityId: oldProjectId,
            parentEntityType: ParentEntityType.Project,
          })
          .modify({
            parentEntityId: project.id,
          });

        // We don't update the project.projectMeasurements since the method
        // syncDirtyMeasurements will already do that. Since all measurements on
        // that project will be new ones
      }
    }
    await this.db.dirtyProjects.delete(dirtyProject.id);
    // TODO: if the just uploaded project is also edited, it needs to be changed in the editor. otherwise changes
    // will still be stored to the previous project-Id => desaster. The same applies for measurements!
    return project;
  }

  public static async syncDirtyMeasurementById(
    dirtyMeasurementId: number | string
  ): Promise<Measurement | null> {
    const dirtyMeasurement = await this.db.dirtyMeasurements.get(
      dirtyMeasurementId
    );
    return await this.syncDirtyMeasurement(dirtyMeasurement!);
  }

  // Returns the synced measurement if it was stored or already existed. If it was deleted returns null
  public static async syncDirtyMeasurement(
    dirtyMeasurement: DirtyEntity
  ): Promise<Measurement | null> {
    // TODO: it could happen, that the parent project wasn't synced to the server yet!
    // verify that first! in the emergency case try to sync the project first. We can't sync
    // a measurement to the server where the parent project isn't there yet.
    let measurement = await this.db.measurements.get(dirtyMeasurement.id);
    let returnMeasurement: Measurement | null = null;
    if (measurement) {
      // when measurement is not send. somewhen an error happened in the sync process.
      // we just delete the id from dirtyMeasurements and ignore it.
      if (measurement.isRestDelete) {
        await Data.deleteMeasurement(measurement);
        returnMeasurement = null;
      } else {
        let oldMeasurementId = null;
        if (isNaN(Number(measurement.id))) {
          // new measurement
          oldMeasurementId = measurement.id;
          measurement.id = null;
          measurement.version = 0;
        }
        try {
          measurement = await RestClient.saveMeasurement(measurement);
        } catch(e) {
          // we assume we've lost the connection.
          // don't do anything else, just return the measurement the way it was stored in the db.
          return measurement;
        }
        await this.db.measurements.put(measurement);
        if (oldMeasurementId) {
          const changedEntity: ChangedEntity = {
            newId: measurement.id as number,
            oldId: oldMeasurementId as string,
            entityType: EntityType.Measurement,
          };
          store.commit("addChangedEntityToQueue", changedEntity);

          // it was a new Measurement
          // delete the old one, with the fake id
          await this.db.measurements.delete(oldMeasurementId);
          // actualize the documents
          await this.db.documents
            .where({
              parentEntityId: oldMeasurementId,
              parentEntityType: ParentEntityType.Measurement,
            })
            .modify({
              parentEntityId: measurement.id,
            });
          returnMeasurement = measurement;
        }
      }
    }
    await this.db.dirtyMeasurements.delete(dirtyMeasurement.id);
    return returnMeasurement;
  }

  private static async syncDirtyMeasurements() {
    const dirtyMeasurements = await this.db.dirtyMeasurements.toArray();
    for (const dirtyMeasurement of dirtyMeasurements) {
      this.syncDirtyMeasurement(dirtyMeasurement);
    }
  }

  public static async syncDirtyDocuments() {
    // TODO: sync deletes first. they are quicker!
    Logger.debug("is already posting documents: " + this.isPostRunning);
    if (!this.isPostRunning) {
      try {
        Logger.debug("start posting documents");
        this.isPostRunning = true;
        const dirtyDocuments = await this.db.dirtyDocuments.toArray();
        for (const dirtyDocument of dirtyDocuments) {
          // add all documents to queue
          store.commit("addUpload", dirtyDocument.id);
        }
        // loop as long as there are dirty documents
        for (
          let dirtyDocumentId;
          (dirtyDocumentId = await store.dispatch("getNextUpload"));

        ) {
          const document = await this.db.documents.get(dirtyDocumentId);
          //if that document doesn0t exist anymore, w e just delete it from dirtydocuments
          if (document) {
            // don't sync a document which parent document was not storred to the server yet!!! (contains a letter in the id)
            if (!isNaN(Number(document.parentEntityId))) {
              if (!document.isRestDelete) {
                const oldDocumentId = document.id!;
                if (isNaN(Number(document.id))) {
                  // that document has never been stored to the server before
                  // remove the document id with a letter in it.
                  document.id = null;
                  document.version = 0;
                  Logger.debug("uploading new document: " + oldDocumentId);
                } else {
                  Logger.debug("updating existing document: " + document.id);
                }
                // upload!
                // TODO: test what happenswith the progress bar, if we loose connection while uploading????
                // TODO: also show how many documents are left, if there is no internet connectoin at all!
                document.id = await RestClient.saveDocument(document);
                document.isDirty = false;
                // TODO: use a transaction !!!
                await this.db.documents.delete(oldDocumentId);
                await this.db.documents.put(document);
                if (oldDocumentId != document.id) {
                  const changedEntity: ChangedEntity = {
                    newId: document.id,
                    oldId: oldDocumentId as string,
                    entityType: EntityType.Document,
                  };
                  store.commit("addChangedEntityToQueue", changedEntity);
                }
              } else {
                Logger.debug("deleting document on server. Id: " + document.id);
                await RestClient.deleteDocument(document);
                await this.db.documents.delete(document.id!);
              }
              await this.db.dirtyDocuments.delete(dirtyDocumentId);
            }
          } else {
            // That document doesn't exist anymore. delete it from dirtyDocuments
            Logger.debug(
              "Tried to sync (upload) a document that doesn't exist anymore. ID: " +
                dirtyDocumentId
            );
            await this.db.dirtyDocuments.delete(dirtyDocumentId);
          }
        }
      } catch (e: any) {
        if (e.isAxiosError) {
          Logger.info("Lost Network connection while uploading documents, aborting.")
        } else {
          Logger.error("Error happened while uplading documents!");
          Logger.logException(e, "SyncData.syncDirtyDocuments");
        }
      } finally {
        this.isPostRunning = false;
        Logger.debug("finished posting documents");
      }
    }
    // TODO: maybe more dirty documents where added meanwhile... recheck database
  }

  public static async initDocumentPostSync() {
    // TODO: implemnte if it's firefox or something similar which doesn't support sync
    // let registration = await this.getServiceWorker();
    // if ('sync' in registration) {
    //   registration.sync.register(SyncEvents.documentsPost);
    // } else {
    this.syncDirtyDocuments();
    // }
  }

  public static async initDocumentFetchSync() {
    //let registration = await this.getServiceWorker();
    // if ('sync' in registration) {
    //   registration.sync.register(SyncEvents.documentsFetch);
    // } else {
    this.fetchDocuments();
    // }
  }

  private static async getServiceWorker(): Promise<ServiceWorkerRegistration> {
    return await navigator.serviceWorker.ready;
  }

  private static async getNewDocumentId(): Promise<string> {
    let number;
    do {
      number = Math.floor(Math.random() * 10000000 + 1);
      number = "a" + number;
    } while (await this.db.documents.get(number));
    return number;
  }
}
